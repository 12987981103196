import { IoIosArrowDown } from "react-icons/io";
import { Fragment, useState } from "react";

const Select = ({
  label,
  id,
  value,
  options,
  className,
  onChange,
  required,
  error,
  color,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const selectedOption = options.find((option) => option.value === value);
  return (
    <div>

      <div className="w-full  flex   flex-col gap-2">
        {label && (
          <label
            htmlFor={label}
            className={` font-semibold text-xs ${color ? color : " text-white"} `}
          >
            {label} {required && <span className="text-secondary-300">*</span>}
          </label>
        )}
        <div className=" flex items-center relative ">
          <select
            {...props}
            required={required}
            id={id}
            value={value}
            onChange={onChange}
            className={`relative inline-flex border items-center font-medium bg-transparent 
            
            ${
              color
                ? " focus:border-secondary-200 "
                : "  focus:border-white  text-white"
            }
            border-neutral-80 focus-within:border-neutral-100   ring-gray-500 focus-within:ring

            focus:outline-0 text-sm rounded border-secondary-200 w-full p-3   ${
              isFocused ? "border-white ring-white " : ""
            } ${className}`}
          >
            {options.map((item) => (
              <option key={item.id} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className=" absolute right-4">
            <IoIosArrowDown color={color ? "black" : " white"} />
          </div>
        </div>
        {error && (
          <p className=" text-secondary-300 text-xs   font-semibold mt-3 uppercase">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Select;
