import Button from "components/Layout/Fields/Button";
import Button2 from "components/Layout/Fields/Button2";
import { Fragment, useRef } from "react";

import toast from "react-hot-toast";

const VideoSection = ({videoSrc}) => {

  const videoRef = useRef(null);



  const show = () => {
    toast.success('MEN WOMEN', {
      style: {
        background: 'rgba(25,25,25)',
        color: 'white'
      },
      // position: 'top-right'
    });
  };

  return (
    <Fragment>
      <div className="relative">
        <video
          className="w-full h-[500px] md:h-[570px] object-cover"
          ref={videoRef}
          autoPlay
          muted
          playsInline
          loop
        >
          {videoSrc && <source src={require('../../assets/images/Home/video.mp4')} type="video/mp4" />}
        </video>
        <div className="absolute bottom-0 w-full h-full px-5 md:px-20 py-10 flex items-end md:items-center">
          <div className="flex flex-col gap-5 w-full">
            <h1 className="tracking-wider text-2xl md:text-5xl uppercase font-semibold text-white">
             #BEONEPERCENT   <br />
             Welcome to the club
            </h1>
            <p className="tracking-wider text-white text-sm">
              And even more new reasons to go gym. See you there.
            </p>
            {/* Uncomment if needed
            <div className="flex flex-col md:flex-row gap-3">
              <Button2
                disabled={false}
                onClick={() => navigate("/shop-women")}
                className={"  "}
              >
                Shop Women
              </Button2>
              <Button
                disabled={false}
                onClick={() => navigate("/shop-men")}
                className={" "}
              >
                Shop Men
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VideoSection;
