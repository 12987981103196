import { db } from "Firestore/Firebase";
import Layout from "components/Layout/Layout";

import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import AccessoriesMain from "components/Products/Accessories/AccessoriesMain";

const Accessories = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "products", "accessories", "products"),
        orderBy("index", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        const filteredCities = cities.filter((item) => item.available === true);

        setData(filteredCities);
        setLoading(false);
      });
    } catch {}
  }, []);

  const [posters, setPosters] = useState([]);
  const [mainPoster, setMainPoster] = useState([]);
  const [poster, setPoster] = useState([]);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "products", "accessories", "posters"),
        orderBy("number", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        const filteredCities = cities.filter((item) => item.available === true);
        setMainPoster(filteredCities[0]);
        setPoster(filteredCities[1]);
        setPosters(filteredCities);
   
      });
    } catch {}
  }, []);

  return (
    <Layout loading={loading} title={"Accessories"}>
      <AccessoriesMain mainPoster={mainPoster} poster={poster} data={data} />
    </Layout>
  );
};

export default Accessories;
