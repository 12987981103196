import Button2 from "components/Layout/Fields/Button2";
import Image from "components/Layout/Fields/Image";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Card = ({ item, index }) => {
  return (
    <Link to={item.path} key={index} className=" ">
      <div className="  relative overflow-hidden rounded  ">
        <div className=" h-[600px]  md:h-[350px]  xl:h-[600px]">
          <Image className={""} src={item.image} alt="" />
        </div>

        <div className="  z-10 bottom-0 p-5 absolute">
          <Button2
            disabled={false}
            //  onClick={''}
            className={"  whitespace-pre md:px-5  lg:px-10  text-xs xl:text-sm"}
          >
            {item.title}
          </Button2>
        </div>
      </div>
    </Link>
  );
};

const Section2 = () => {
  const data = [
    {
      id: 1,
      image:
        require('../../assets/images/Home/shopwomencover.jpg'),
      title: "SHOP WOMEN",
      path:'/shop-women'
    },
    {
      id: 2,
      image:
      require('../../assets/images/Home/shopmencover.jpg'),

      title: "SHOP MEN",
      path:'/shop-men'
    },
    {
      id: 3,
      image:
      require('../../assets/images/Home/shopaccessoriescover.jpg'),

      title: "SHOP ACCESSORIES",
      path:'/accessories'
    },
  ];

  return (
    <section>
      <div className="   flex  flex-col gap-5 md:gap-10 w-full ">
        <h1 className="  text-white font-semibold text-xl  md:text-2xl uppercase">
          Ready To Join The Club ? / Join The Club!
        </h1>

        <div className=" ">
          <div className=" grid md:grid-cols-3 gap-3">
            {data.map((item, index) => (
              <Card item={item} key={index} index={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
