import React, { Fragment } from "react";
import MainPoster from "../MainPoster";
import ProductsSection from "../ProductsSection";
import ProductPoster from "../ProductPoster";

const WomenMain = ({ data, poster, mainPoster }) => {
  const img1 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F6vIH1BqTnb7pyiEKhQfEK6%2Fd58b416049661af41b38adb2f5d84bd4%2FMay_Key_Looks_Web_Banners_Desktop_Womens_1.jpg&w=3840&q=85";
  const img2 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F4dAD8FshkRrFlzUjpRpgv3%2F22e9ab7920eb8711fe5781fa9191b1f9%2Fcollection-banner-womens-pink.png&w=3840&q=85";

  const button1 = {
    title: "",
    path: "",
  };
  const button2 = {
    title: "",
    path: "",
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10 pb-20 ">
        {mainPoster && (
          <MainPoster
            className={`h-[200px] md:h-[500px]`}
            mainPoster={mainPoster}
          />
        )}
          <div className=" md:pt-10">
          <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
            <ProductsSection
              data={data}
              title={"NEW DROPS"}
              title2={"WOMEN"}
            />
          </div>
        </div>

        {poster && (
          <ProductPoster className={`h-[400px] md:h-[600px]`} poster={poster} />
        )}
      </div>
    </Fragment>
  );
};

export default WomenMain;
