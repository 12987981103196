import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import Logo from "assets/images/logo.png";
import { FiHeart, FiMenu } from "react-icons/fi";
import { BsBag } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SearchField from "./Fields/SearchField";
import { HiMiniXMark } from "react-icons/hi2";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Button from "./Fields/Button";
import Button2 from "./Fields/Button2";
import toast from "react-hot-toast";
import { auth, db } from "Firestore/Firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import ProductsCards from "components/Cards/ProductsCards";
import Image from "./Fields/Image";
import Currency from "react-currency-formatter";

const Card = ({ item, openSearch }) => {
  const navigate = useNavigate();

  const handleProductClick = (item) => {
    navigate(`/products/${item.category}/${item.id}`);
    openSearch();
  };

  return (
    <div className="  group   pb-4 relative  ">
      <div
        onClick={() => handleProductClick(item)}
        className="flex   flex-col gap-2 cursor-pointer "
      >
        <div className="   h-[200px] sm:h-[450px] relative overflow-hidden rounded">
          <Image
            className={`  ${item?.image2 ? " md:group-hover:hidden" : ""} `}
            src={item?.image}
            alt=""
          />
          {item?.image2 && (
            <Image
              className={`md:group-hover:flex   hidden `}
              src={item?.image2}
              alt=""
            />
          )}
        </div>
        <div>
          <div className="  flex px-2  w-fit  gap-1 flex-col">
            <div className=" flex flex-col gap-1">
              <h1 className="  text-secondary-100  font-medium text-sm">
                {item?.name}
              </h1>
              <h1 className=" text-xs   text-secondary-300  font-light">
                {item?.subtitle}
              </h1>
            </div>
            <h1 className="  text-primary-100 font-medium text-xs">
              <Currency quantity={item?.price} currency="inr" />
            </h1>
          </div>
        </div>
      </div>

      {/* <div className=" absolute  right-2 top-2">
      <button
        // onClick={openModal}

        onClick={() =>
          user
            ? wishCheck
              ? removeWishlist(item.id)
              : addWishlist(item)
            : openModal(item)
        }
        className={`
        flex     rounded-full  overflow-hidden
        p-2    bg-backgound-300 text-secondary-200  `}
      >
        <Icon size={16} color="black" />
      </button>
    </div> */}
    </div>
  );
};

const Search = ({
  searchSection,
  menuRef,
  openSearch,
  search,
  setSearch,
  handleInputChange,
  data,
}) => {
  const options = [
    { id: 2, title: "Men", path: "/shop-men" },
    { id: 3, title: "Women", path: "/shop-women" },
    { id: 4, title: "Accessories", path: "/accessories" },
  ];

  return (
    <div>
      <div
        className={`${
          searchSection ? "opacity-100" : "opacity-0 hidden"
        }  bg-backgound-100 h-[100vh] overflow-auto w-full transition-opacity ease-in-out fixed z-50`}
      >
        <div ref={menuRef} className="  fadeNav ">
          <div className=" bg-white    min-h-[100vh]  md:min-h-[200px]">
            <div className=" flex flex-col gap-4 p-5 md:p-7 ">
              <div className="   flex justify-between  gap-5  md:gap-10 items-center">
                <div className=" w-full">
                  <SearchField
                    setSearch={setSearch}
                    value={search}
                    onChange={handleInputChange}
                    placeholder={"SEARCH ANYTHING"}
                  />

                  {/* 
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  /> */}
                </div>

                <button
                  onClick={openSearch}
                  className={`items-center flex hover:bg-gray-300 p-1 rounded-full `}
                >
                  <HiMiniXMark size={28} className=" text-secondary-100 " />
                </button>
              </div>

              <div className=" grid grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-4">
                {data?.map((item, index) => (
                  <Card key={index} openSearch={openSearch} item={item} index />
                ))}
              </div>

              {data?.length === 0 && <h1>No Result Found</h1>}

              <div className=" flex-col  flex gap-4">
                <h1 className=" uppercase font-medium">Popular Search Terms</h1>

                <ul className=" flex gap-3">
                  {options.map((item, index) => (
                    <NavLink
                      onClick={openSearch}
                      to={item.path}
                      key={index}
                      className={`
                 bg-tertiary-100 p-2 text-sm rounded cursor-pointer uppercase  hover:text-secondary-200  font-medium text-secondary-100
              `}
                    >
                      {item.title}
                    </NavLink>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SideMenu = ({ options, location, sideOptions, closeMenu, user }) => {
  const navigation = useNavigate();
  const logOut = () => {
    auth.signOut();
    navigation("/");
    toast.success("LogOut Successfully", {
      style: {
        background: "rgba(25,25,25)",
        color: "white",
        fontSize: "13px",
        textTransform: "uppercase",
      },
    });
  };

  return (
    <div>
      <div
        className={` 
        absolute h-[100vh]  w-full  transition-opacity ease-in-out  bg-white z-50`}
      >
        <div className=" p-5 flex flex-col gap-5 ">
          <ul className=" flex flex-col gap-3 ">
            {options.map((item, index) => (
              <li
                onClick={closeMenu}
                key={index}
                className={`
                ${
                  item.title === "Home" ? "" : " border-t"
                }    w-full     pt-4     border-secondary-300 `}
              >
                <NavLink
                  to={item.path}
                  className={`

                 
                  ${location.pathname === item.path ? "" : " "}
                   tracking-wide   font-semibold text-base uppercase   text-secondary-100 flex justify-between items-center  `}
                >
                  {item.title}

                  <MdOutlineArrowForwardIos
                    size={20}
                    className=" text-secondary-100"
                  />
                </NavLink>
              </li>
            ))}
          </ul>
          {user && (
            <Fragment>
              <div>
                <ul className=" flex flex-col gap-3 ">
                  {sideOptions.map((item, index) => (
                    <li
                      onClick={closeMenu}
                      key={index}
                      className={`
                  ${
                    item.id === 1 ? "" : " border-t"
                  }    w-full     pt-4     border-secondary-300 `}
                    >
                      <NavLink
                        to={item.path}
                        className={`
  
                   
                    ${location.pathname === item.path ? "" : " "}
                     tracking-wide     text-sm uppercase   text-secondary-100 flex justify-between items-center  `}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <Button onClick={logOut}>LOGOUT</Button>
            </Fragment>
          )}

          {!user && (
            <div className=" flex flex-col gap-3 py-4">
              <Button
                onClick={() => navigation("/login", { state: { login: 1 } })}
              >
                LOGIN
              </Button>
              <Button2
                onClick={() => navigation("/login", { state: { login: 2 } })}
                className={"  border  border-border-100"}
              >
                REGISTER
              </Button2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Navbar = ({ user, wishlist, cartData, wishCount }) => {
  const menuRef = useRef(null);
  const [searchSection, setSearchSection] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigation = useNavigate();

  const openSearch = () => {
    setSearchSection(!searchSection);
    setSearch("");
  };
  const openMenu = () => {
    setSideMenu(!sideMenu);
  };
  const closeSearch = () => {
    setSearchSection(false);
  };
  const closeMenu = () => {
    setSideMenu(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setSearchSection(false);
      setSearch("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    { id: 1, title: "Home", path: "/" },
    { id: 2, title: "Men", path: "/shop-men" },
    { id: 3, title: "Women", path: "/shop-women" },
    { id: 4, title: "Accessories", path: "/accessories" },
    // { id: 5, title: "Our Story", path: "/ourstory" },
  ];
  const sideOptions = [
    { id: 1, title: "My Account", path: "/account" },
    { id: 2, title: "Wishlist", path: "/wishlist" },
    // { id: 2, title: "Address", path: "/" },
  ];

  const iconsPath = [
    {
      id: 1,
      Icon: FiHeart,
      path: "/wishlist",
      count: wishCount ? wishCount : 0,
    },
    { id: 2, Icon: BsBag, path: "/cart", count: cartData.length },
    // { id: 3, Icon: FiUser, path: "/login" },
  ];


  const [currentIndex, setCurrentIndex] = useState(0);
  const [promotions, setPromotions] = useState(null);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "promotions"),
        orderBy("createdAt", "desc")
      );
      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });

        const filteredCities = cities.filter((item) => item.available === true);

        setPromotions(filteredCities);
      });
    } catch {}
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === promotions?.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [promotions]);

  const Icon = sideMenu ? HiMiniXMark : FiMenu;

  const homeNavigate = () => {
    navigation("/");
    closeSearch();
    closeMenu();
  };

  useEffect(() => {
    if (searchSection) {
      try {
        const menQuery = query(collection(db, "products", "men", "products"));
        const womenQuery = query(
          collection(db, "products", "women", "products")
        );
        const accessoriesQuery = query(
          collection(db, "products", "accessories", "products")
        );

        Promise.all([
          new Promise((resolve) => {
            onSnapshot(menQuery, (querySnapshot) => {
              const menProducts = [];
              querySnapshot.forEach((doc) => {
                menProducts.push({ id: doc.id, ...doc.data() });
              });
              resolve(menProducts);
            });
          }),
          new Promise((resolve) => {
            onSnapshot(womenQuery, (querySnapshot) => {
              const womenProducts = [];
              querySnapshot.forEach((doc) => {
                womenProducts.push({ id: doc.id, ...doc.data() });
              });
              resolve(womenProducts);
            });
          }),
          new Promise((resolve) => {
            onSnapshot(accessoriesQuery, (querySnapshot) => {
              const accessoriesProducts = [];
              querySnapshot.forEach((doc) => {
                accessoriesProducts.push({ id: doc.id, ...doc.data() });
              });
              resolve(accessoriesProducts);
            });
          }),
        ])
          .then((results) => {
            const combinedProducts = results.flat();
            const filteredProducts = combinedProducts.filter(
              (item) => item.available === true
            );
            setData(filteredProducts);
          })
          .catch(() => {});
      } catch {}
    } else {
    }
  }, [searchSection]);
  const handleButtonClick = () => {
    setSearch("");
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = useMemo(() => {
    if (search?.length > 0) {
      const filtered = data.filter((item) =>
        item.name?.toLowerCase().includes(search.toLowerCase())
      );
      return filtered;
    }
  }, [data, search]);



  return (
    <Fragment>
      <Search
        data={filteredData}
        handleButtonClick={handleButtonClick}
        handleInputChange={handleInputChange}
        search={search}
        setSearch={setSearch}
        searchSection={searchSection}
        menuRef={menuRef}
        openSearch={openSearch}
      />

      <div className=" py-6  px-3   bg-primary-100 relative   ">
        {/* Promotions */}
        <div className="   relative   w-full flex justify-center  items-center">
          {promotions?.map((item, index) => (
            <div key={index} className={`absolute`}>
              <h1
                className={`${
                  currentIndex === index
                    ? "opacity-100 fadeInUp  "
                    : "opacity-0"
                } text-center transition-all delay-75 w-full h-full text-xs md:text-sm  font-medium uppercase  text-white`}
              >
                {item.title}
              </h1>
            </div>
          ))}
        </div>
      </div>

      <div className="  z-20  sticky top-0 bg-secondary-100 w-full   ">
        <div className="py-4 md:px-10  px-5">
          <div className=" xl:container flex justify-between lg:grid  grid-cols-8  items-center">
            {/* Logo */}
            <div
              onClick={homeNavigate}
              className="  w-16 h-11 cursor-pointer  col-span-2"
            >
              <img className=" w-full h-full " src={Logo} alt="" />
            </div>

            {/* Options */}
            <div className="  hidden lg:flex justify-center col-span-4">
              <ul className=" flex gap-5 justify-center">
                {options.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={item.path}
                      className={`
                  ${
                    location.pathname === item.path
                      ? " border-primary-100"
                      : "border-transparent "
                  }
                  text-sm  text-white border-b-2 uppercase  pb-1 tracking-wide   hover:border-primary-100  `}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            {/* Icons */}
            <div className=" flex items-center justify-end gap-5 col-span-2">
              <div
                onClick={openSearch}
                className=" hidden xl:flex gap-2 items-center border px-6 py-2 rounded border-border-100 hover:border-white cursor-pointer"
              >
                <IoSearch size={20} className=" text-white" />
                <h1 className=" text-white text-sm uppercase">Search</h1>
              </div>
              <div>
                <ul className=" flex gap-2">
                  <li
                    onClick={openSearch}
                    className=" xl:hidden flex rounded-full  overflow-hidden"
                  >
                    <button className={`  hover:bg-gray-800 p-2`}>
                      <IoSearch size={20} className=" text-white " />
                    </button>
                  </li>

                  {iconsPath.map((item, index) => (
                    <NavLink
                      onClick={closeMenu}
                      to={item.path}
                      className=" cursor-pointer relative "
                    >
                      <li
                        key={index}
                        className=" flex rounded-full relative  overflow-hidden"
                      >
                        <span className={`  hover:bg-gray-800 p-2`}>
                          <item.Icon size={20} className=" text-white " />
                        </span>
                      </li>
                      <div className=" cursor-default top-0.5 w-4 h-4 border border-white flex justify-center  items-center rounded-full right-0   absolute bg-primary-100">
                        <h1 className=" text-[10px] text-white">
                          {item.count}
                        </h1>
                      </div>
                    </NavLink>
                  ))}
                  <li className=" hidden  lg:flex rounded-full  overflow-hidden">
                    <NavLink
              onClick={closeMenu}

                      to={user ? "/account" : "/login"}
                      className={`  hover:bg-gray-800 p-2`}
                    >
                      <FiUser size={20} className=" text-white " />
                    </NavLink>
                  </li>
                  <li className="    lg:hidden rounded-full  overflow-hidden">
                    <button
                      onClick={openMenu}
                      className={`  hover:bg-gray-800 p-2`}
                    >
                      <Icon size={20} className=" text-white " />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {sideMenu && (
          <SideMenu
            user={user}
            closeMenu={closeMenu}
            sideMenu={sideMenu}
            options={options}
            location={location}
            sideOptions={sideOptions}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Navbar;
