import Button2 from "components/Layout/Fields/Button2";
import Image from "components/Layout/Fields/Image";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {

  const navigate = useNavigate();

  return (
    <div className=" ">
      <div className="  h-[100vh] relative">
        <Image src={require('../../assets/images/Home/404page.jpg')} />
        <div className=" top-0 absolute w-full h-full   flex    md:gap-56  py-10 md:py-20  md:justify-start justify-between flex-col  items-center  bg-black/30">
          <h1 className=" uppercase text-white text-3xl font-semibold tracking-wider">
            One Percent Club Aw
          </h1>
          <div className=" flex  flex-col gap-5 items-center">
            <h1 className="uppercase text-white text-5xl font-semibold tracking-wider">
              404
            </h1>
            <div className="flex  gap-3 flex-col items-center">
              <p className="uppercase text-white text-xl font-semibold tracking-wider">
                404 PAGE NOT FOUND
              </p>
              <p className=" text-sm   font-light text-white">
                We can't seem to find the page that you were looking for.
              </p>
              <div className=" flex  w-full md:flex-row flex-col   gap-2 md:gap-5 pt-2">
                <Button2 
                        onClick={() => navigate("/shop-women")}
                className={' uppercase  px-14'}>
                    Shop Women

                </Button2>
                <Button2
                         onClick={() => navigate("/shop-men")}
                className={' uppercase  px-14'}>
                    Shop Mens

                </Button2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
